@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

.code-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
    pointer-events:none;
    }

    svg {
        pointer-events:none;
        }

        .apexcharts-canvas svg{
            pointer-events: visible;
        }

.ellipsis-tooltip{
    overflow: hidden !important;
    max-width: 300px;
    white-space: nowrap;      
    text-overflow: ellipsis;
}

.cron-nav {
    display: flex;
    justify-content: start;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity)); 
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
    border-bottom-width: 1px;
}

.cron-nav-item {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.cron-nav-link {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.cron-nav-link:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    border-bottom-width: 2px;
}

.cron-active {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
    border-bottom-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.cron-active:hover {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
    border-bottom-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
}


.c-tab-content {
    margin-top:0;
    padding: 0.75rem;    
    display: flex;
    flex-direction: column;
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.cron-form-group {
    padding: 0.5rem;
    --tw-ring-color: rgb(15 23 42 / .1);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    margin-bottom: 0.5rem;
    border-radius: 0.375rem;
    background-color: white;
}

.recron [tab-name="HOURS"] .cron-form-group.c-every{
    display: none;
}

.recron [tab-name="HOURS"] .cron-form-group.c-increment{
    display: none;
}

.recron [tab-name="HOURS"] .cron-form-group.c-range{
    display: none;
}

.cron-form-check {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.cron-form-check-input {
    border-radius: 100%;
    border-width: 1px;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    border-radius: 100%;
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
    background-color: #fff;
    border-width: 1px;
    --tw-shadow: 0 0 #0000;
}

.cron-form-check-input:disabled~.cron-form-check-label, .cron-form-check-input[disabled]~.cron-form-check-label{
    opacity: .4;
}



[type='checkbox']:focus,
[type='radio']:focus {
    --tw-ring-opacity: 0;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}


[type='checkbox']:disabled,
[type='radio']:disabled {
    cursor: default;
    opacity: .4;
}

.cron-form-inline{
    display: flex;
    align-items: center;
    vertical-align: middle;
    flex-wrap: wrap;
    gap: 0.5rem 0rem;
}

.cron-form-control{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
    --tw-ring-inset: inset;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
    padding-right: 2.5rem;
    padding-left: 0.75rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    border-width: 0px;
    border-radius: 0.375rem;
    width: max-content;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.cron-form-control:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}

.cron-row{
    display: flex;
    flex-wrap: wrap;
    padding-top: .75rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    gap: 0.75rem;

}


.cron-col-2{
    position: relative;
    width: 100%;
    margin-right: 15px;
    margin-left: 15px;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.cron-col-3{
    position: relative;
    width: 100%;
    margin-right: 15px;
    margin-left: 15px;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}