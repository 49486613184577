
:root {
    --rs-input-focus-border: #6b7280;    
    --rs-calendar-cell-selected-hover-bg:  #6b7280;
    --rs-bg-active: #6b7280;
    --rs-active: white;
    --rs-calendar-range-bg: #e5e7eb;
    --rs-state-hover-bg:#6b7280; 
    --rs-listbox-option-hover-bg:  #9ca3af;
    --rs-listbox-option-hover-text: white;
    --rs-btn-primary-bg:#4b5563;
    --rs-btn-primary-hover-bg:#6b7280;
    --rs-btn-link-text: #4b5563;
    --rs-btn-link-hover-text: #6b7280;
    --rs-picker-value: #4b5563;
    --rs-picker-count-bg:  #9ca3af;
    --rs-checkbox-checked-bg: #4b5563;
    --tw-ring-color: #4b5563;
    --rs-primary-500: #6b7280;
}
.rs-input-group-focus{
    outline: none !important ;
}
.rs-input-group:focus-within{
    outline: none !important ;
}

.rs-picker:focus-within{
    outline: none !important ;
}

.rs-picker-toolbar-right > button{
    background-color: #4b5563;
}

.rs-date-range-input.rs-input{
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.rs-picker-cascader-menu .rs-check-item.rs-check-item-focus .rs-checkbox-checker > label, .rs-picker-cascader-menu .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label{
    font-weight: normal;
}

.rs-picker-cascader-menu-column{
    width: max-content !important;
}

.rs-picker-cascader-menu-items{
    width: max-content !important;
}

.rs-picker-list-item > .rs-check-item {
    width: 100% !important;
}

.rs-search-box-input {
    outline: none !important;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
}