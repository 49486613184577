@keyframes spin2 {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/*--border-bg: conic-gradient(#ffffff,#ffffff,#4ade80,#22c55e,#4ade80,#ffffff,#ffffff);*/
.rotating-border {
  --border-radius: 0.5rem;
  --border-size: 0.1rem;
  --border-bg: conic-gradient(#ffffff, #ffffff, #4ade80, #22c55e, #4ade80, #ffffff, #ffffff);
  --padding: 0rem;
  background-color: transparent;
  border-radius: var(--border-radius);
  padding: calc(var(--padding) + var(--border-size));
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.rotating-border::after {
  content: '';
  position: absolute;
  inset: var(--border-size);
  background: white;
  z-index: -1;
  border-radius: calc(var(--border-radius) - var(--border-size));
}

.rotating-border::before {
  content: '';
  display: block;
  background: var(--border-bg);
  width: calc(100% * 1.41421356237);
  padding-bottom: calc(100% * 1.41421356237);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  z-index: -2;
  animation: spin2 3s linear infinite;
}

.xsmall-loader{
  --loader-size: 1rem;
  --loader-sub-size: 0.75rem;
  --loader-border-size: 1.5px;
}

.small-loader{
  --loader-size: 1.25rem;
  --loader-sub-size: 1.0rem;
  --loader-border-size: 2px;
}

.medium-loader{
  --loader-size: 2rem;
  --loader-border-size: 2px;
}


.big-loader{
  --loader-size: 48px;
  --loader-border-size: 4px;
}

:root{
  --loader-color: #374151;
  --loader-sub-color: #9ca3af;
}

.loader-light{
  --loader-color:#ffffff;
  --loader-sub-color:#ffffff;
}

.loader1 {  
  width: var(--loader-size);
  height: var(--loader-size);
  border-top: var(--loader-border-size) solid var(--loader-color);
  border-right: var(--loader-border-size) solid transparent;
  border-radius: 50%;
  display: inline-block; 
  box-sizing: border-box;
  animation: rotation1 1s linear infinite;
}

.loader1::after {
  content: '';
  box-sizing: border-box;
  width: var(--loader-sub-size);
  height: var(--loader-sub-size);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  border-left: var(--loader-border-size) solid var(--loader-sub-color);
  border-bottom: var(--loader-border-size) solid transparent;
  animation: rotation1 0.5s linear infinite reverse;
}

@keyframes rotation1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation2 1s linear infinite;
}
.loader2::after,
.loader2::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack2 0.5s linear infinite;
  transform-origin: center center;
}
.loader2::before {
  width: 60%;
  height: 60%;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation2 1.5s linear infinite;
}
    
@keyframes rotation2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    


.loaderrepo {
  width: 15px;
  height: 20px;
  margin-left: 15px;
  background: #374151;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: bumpx 0.4s ease-in infinite alternate;
}
.loaderrepo::after {
  content: '';  
  box-sizing: border-box;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  position: absolute;
  border: 15px solid transparent;
  border-top-color: #374151;
}

@keyframes bumpx {
  0% {
    transform: translate(-50%, 5px);
  }
  100% {
    transform: translate(-50%, -5px);
  }
}
    